$(document).ready(function (e) {
	//Wrap navigation to more dropdown link
	$("nav.nav-sec ul.nav-list").navwrap();

	//Mobile navigation
	$(".nav-list").slicknav({
		prependTo: $("header"),
	});

	//Sticky Header
	$(window).scroll(function () {
		var scroll = $(window).scrollTop();
		if (scroll > 115) {
			$('.sticky-header').css('margin-top', '0px');
		} else {
			$('.sticky-header').css('margin-top', '-50px');
		}
	});

	//Add Are You Human spam check box
	if ($(".signup_form input[type=submit]").length) {
		$(".signup_form input[type=submit]")
			   .parent(".row")
			   .before(
					 '<div class="row wide checkbox"><input type="checkbox" name="human" id="human"><label for="human">Check this box if you are a real person</label></div>'
					 );
	}

	//Themes slide on themes pages
	if ($("#themeslides .images").length) {
		$("#themeslides .images").carouFredSel({
			width: 720,
			pagination: "#slider_pag",
			scroll: {
				items: 1,
				duration: 1000,
				pauseOnHover: true,
				fx: "crossfade",
			},
			prev: ".arrow-next",
			next: ".arrow-prev",
		});
	}

	//Update Price
	if ($("#price-list").length) {
		$(".price .val").text($("#price-list select").val());
		$(".users p").html(userString($("#price-list select").find("option:selected").text()));
		$('.price, .divider, .users').show();
		if ($("#price-list select").find(':selected').data('id') == 'Agent1') {
			$('#free-trial-note').hide();
		} else {
			$('#free-trial-note').show();
		}
		$("#price-list select").on("change", function () {
			var price = $(this).val();
			var value = $(this).find(':selected').data('id');
			var users = $(this).find("option:selected").text();

			$(".price .val").text(price);
			$(".users p").html(userString(users));
			if (value == 'Agent1') {
				$('#free-trial-note').hide();
			} else {
				$('#free-trial-note').show();
			}

			$('#price_list_id').val(value);
			if ($("#price_list_id").find(':selected').val() == 'Agent1') {
				$('#trial_period_id').hide();
				$('#subscriber_trial').prop('checked', false);
			} else {
				$('#trial_period_id').show();
			}
			if (window.sessionStorage) {
				sessionStorage.setItem("price", price);
				sessionStorage.setItem("id", value);
				sessionStorage.setItem("users", userString(users));
			}
		});
	}

	if (window.sessionStorage) {
		if (sessionStorage.getItem("price")) {
			var price = sessionStorage.getItem("price");
			var id = sessionStorage.getItem("id");
			$(".price .val").text(price);
			$('#price-list select option[value="' + price + '"]').attr(
				   "selected",
				   true
				   );
			$('#price_list_id option[value="' + id + '"]').attr("selected", true);
			if ($("#price-list select").find(':selected').data('id') == 'Agent1') {
				$('#free-trial-note').hide();
			} else {
				$('#free-trial-note').show();
			}
		}
		if (sessionStorage.getItem("users")) {
			$(".users p").html(sessionStorage.getItem("users"));
		}
	}

	function userString(users) {
		var n = users.search('with') + 5 || 0;
		if (n > 0) {
			var part1 = users.substring(0, n - 1);
			var part2 = users.substring(n, users.length);
			users = part1 + '<span>' + part2 + '</span>'
		}
		return users;
	}

	if ($.isFunction($.fn.magnificPopup)) {
		$(".video-icon").magnificPopup({type: "iframe"});
	}

	if ($(".propholder").length) {
		$(".propholder").each(function () {
			$(this).height($(this).outerHeight());
		});
	}

	/* Check the site type is agent then hide the trial period option*/
	if ($('#price_list_id').val() == 'Agent1') {
		$('#trial_period_id').hide();
		$('#subscriber_trial').prop('checked', false);
	}
	/* Check the site type and show/hide the trial period option*/
	$('#price_list_id').on('change', function () {
		var value = this.value;
		var price = $(this).find(':selected').data('price');
		$('#price-list select').val(price);
		var users = $("#price-list select").find(':selected').text();
		var price = $("#price-list select").find(':selected').val();
		$(".price .val").text(price);
		$(".users p").html(userString(users));
		if (value == 'Agent1') {
			$('#trial_period_id').hide();
			$('#subscriber_trial').prop('checked', false);
		} else {
			$('#trial_period_id').show();
		}
		if (window.sessionStorage) {
			sessionStorage.setItem("price", price);
			sessionStorage.setItem("id", value);
			sessionStorage.setItem("users", userString(users));
		}
	});

	if ($(".features-slider").length) {
		$(".features-slider").owlCarousel({
			dots: false,
			nav: true,
			responsiveClass: true,
			margin: 20,
			autoWidth: true,
			responsive: {
				0: {
					items: 1,
				},
				740: {
					items: 3,
				},
			},
		});
	}

});
